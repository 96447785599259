export const pickFromObject = (obj, keys) => {
  if (obj && typeof obj === 'object' && Array.isArray(keys))
    return (
      keys
        // .filter(key => key in obj)
        .reduce((acc, elem) => {
          if (typeof elem === 'object') {
            const [innerObjectKeyAndItems] = Object.entries(elem);

            if (
              innerObjectKeyAndItems &&
              innerObjectKeyAndItems.length === 2 &&
              typeof innerObjectKeyAndItems[0] === 'string'
            )
              return {
                ...acc,
                [innerObjectKeyAndItems[0]]: pickFromObject(obj[innerObjectKeyAndItems[0]], innerObjectKeyAndItems[1]),
              };
            // } else if (typeof obj[elem] === 'string' || typeof obj[elem] === 'number') {
          } else if (typeof elem === 'string') {
            return { ...acc, [elem]: obj[elem] };
          }
          return acc;
        }, {})
    );
  return {};
};
