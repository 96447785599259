export const apiStatusesCombine = (...statuses: string[]) => {
  const combinedStatus = statuses.reduce((combinedStatus, status) => {
    if (status === 'error') combinedStatus = status;
    else if (status === 'loading' && combinedStatus !== 'error') combinedStatus = status;
    else if (combinedStatus === '') combinedStatus = status;
    return combinedStatus;
  }, '');

  return combinedStatus;
};
