export namespace Board {
  export type Type = {
    BoardBedOnly: string;
    BoardBedBreakfast: string;
    BoardHalfShort: string;
    BoardFull: string;
    BoardAllInclusiveLight: string;
    BoardAllInclusive: string;
    BoardUltraAllInclusive: string;
  };

  export const Types = Object.freeze({
    BOARD_BEDONLY: 1,
    BOARD_BB: 2,
    BOARD_HB: 3,
    BOARD_FB: 4,
    BOARD_AI: 5,
    BOARD_UAI: 6,
    BOARD_AIL: 7,
  });

  export const Abbreviations = Object.freeze({
    [Types.BOARD_BEDONLY]: 'BO',
    [Types.BOARD_BB]: 'BB',
    [Types.BOARD_HB]: 'BB',
    [Types.BOARD_FB]: 'FB',
    [Types.BOARD_AIL]: 'AIL',
    [Types.BOARD_AI]: 'AI',
    [Types.BOARD_UAI]: 'UAI',
  });

  export const Names = (boardTranslations: Board.Type) => {
    return Object.freeze({
      [Types.BOARD_BEDONLY]: boardTranslations?.BoardBedOnly,
      [Types.BOARD_BB]: boardTranslations?.BoardBedBreakfast,
      [Types.BOARD_HB]: boardTranslations?.BoardHalfShort,
      [Types.BOARD_FB]: boardTranslations?.BoardFull,
      [Types.BOARD_AIL]: boardTranslations?.BoardAllInclusiveLight,
      [Types.BOARD_AI]: boardTranslations?.BoardAllInclusive,
      [Types.BOARD_UAI]: boardTranslations?.BoardUltraAllInclusive,
    });
  };
}
