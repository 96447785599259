import {
  FaWalking,
  FaSkiing,
  FaUniversity,
  FaPlaneDeparture,
  FaShuttleVan,
  FaTrain,
  FaCreditCard,
  FaWallet,
  FaUtensils,
  FaShoppingBasket,
  FaShoppingBag,
} from 'react-icons/fa';

export const LocationType = Object.freeze({
  SKI_LIFT: 66,
  CENTER: 1,
  AIRPORT: 2,
  BUS_STATION: 5,
  TRAIN_STATION: 61,
  CASH_MACHINE: 23,
  EXCHANGE_OFFICE: 67,
  RESTAURANT: 3,
  SUPERMARKET: 68,
  SHOP: 4,
});

export const locationTypeIcons = {
  0: <FaWalking />,
  [LocationType.SKI_LIFT]: <FaSkiing />,
  [LocationType.CENTER]: <FaUniversity />,
  [LocationType.AIRPORT]: <FaPlaneDeparture />,
  [LocationType.BUS_STATION]: <FaShuttleVan />,
  [LocationType.TRAIN_STATION]: <FaTrain />,
  [LocationType.CASH_MACHINE]: <FaCreditCard />,
  [LocationType.EXCHANGE_OFFICE]: <FaWallet />,
  [LocationType.RESTAURANT]: <FaUtensils />,
  [LocationType.SUPERMARKET]: <FaShoppingBasket />,
  [LocationType.SHOP]: <FaShoppingBag />,
};
