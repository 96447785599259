export const PicGroup = Object.freeze({
  HOTEL: 101,
  FOOD: 102,
  BUSINESS: 103,
  RECREATION: 104,
  ENTERTAINMENT: 105,
  NIGHTLIFE: 106,
  CHILDREN: 107,
  BEACH: 108,
  ACCOMMODATION: 109,
});
