import { lowercaseFirstLetter } from '../';
import { CurrencySign } from '../../const';

export const transfersTableContent = (transfers, hotel, translations) => {
  const responsiveTableContentTransfers = {
    Head: {
      title: hotel?.village_data?.caption + ' - ' + lowercaseFirstLetter(translations?.VarTransfers),
      columns: transfers?.['transfer-vehicle-type']
        ? Object.entries(transfers['transfer-vehicle-type']).reduce((headColumns, [vehicleTypeId, vehicleType]) => {
            return {
              ...headColumns,
              [vehicleTypeId]: vehicleType + ' (' + transfers['transfer-vehicle-persons'][vehicleTypeId] + ')',
            };
          }, {})
        : '',
    },
    Rows: transfers?.transferprices
      ? Object.values(transfers.transferprices)
          .filter(transferPrice => {
            return (
              (transferPrice.from_place === hotel?.village_data?.transfer_location_id ||
                transferPrice.to_place === hotel?.village_data?.transfer_location_id) &&
              transferPrice.rate > 0
            );
          })
          .reduce((formattedPriceRows, transferPrice) => {
            if (formattedPriceRows && formattedPriceRows[transferPrice?.from_place + '-' + transferPrice?.to_place]) {
              formattedPriceRows[transferPrice?.from_place + '-' + transferPrice?.to_place] = {
                ...formattedPriceRows[transferPrice?.from_place + '-' + transferPrice?.to_place],
                columns: {
                  ...formattedPriceRows[transferPrice?.from_place + '-' + transferPrice?.to_place].columns,
                  [transferPrice.ts_car_type]: transferPrice.rate + CurrencySign[transferPrice?.currency],
                },
              };
            } else {
              formattedPriceRows[transferPrice?.from_place + '-' + transferPrice?.to_place] = {
                title:
                  transfers?.transferdestinations[transferPrice?.from_place]?.name +
                  ' - ' +
                  transfers?.transferdestinations[transferPrice?.to_place]?.name,
                from_place: transferPrice?.from_place,
                to_place: transferPrice?.to_place,
                columns: {
                  [transferPrice.ts_car_type]: transferPrice.rate + CurrencySign[transferPrice?.currency],
                },
              };
            }
            return formattedPriceRows;
          }, {})
      : {},
  };
  return responsiveTableContentTransfers;
};

export const defaultTransfersTableContent = {
  Head: {},
  Rows: {},
};
