export namespace Deal {
  export const DealGroup = Object.freeze({
    DEAL_EARLY_BOOKING: 1,
    DEAL_LAST_MINUTE: 2,
    DEAL_HOLIDAY_OFFER: 3,
    DEAL_EVENT_OFFER: 4,
    DEAL_WEEKEND_PACKAGE: 5,
    DEAL_GUARANTEED_PACKAGE: 6,
  });
  export const DiscountType = Object.freeze({
    DISCOUNT_PERCENT: 1,
    DISCOUNT_FREE_OVERNIGHTS: 2,
    DISCOUNT_PRICE_PER_DAY: 3,
    DISCOUNT_PRICE_PER_STAY: 4,
    DISCOUNT_PER_PERSON_PER_DAY: 5,
    DISCOUNT_PER_ROOM_PER_DAY: 6,
    DISCOUNT_ADDITIONAL_SERVICES: 7,
    DISCOUNT_NORMAL_PRICE: 8,
  });
}
