import bgflag from '../assets/images/flags/bg.jpg';
import deflag from '../assets/images/flags/de.jpg';
import enflag from '../assets/images/flags/en.jpg';
import elflag from '../assets/images/flags/el.jpg';
import ruflag from '../assets/images/flags/ru.jpg';

export const AppLanguages = Object.freeze({
  en: 'English',
  bg: 'Български',
  el: 'Greek',
  de: 'Deutsch',
  ru: 'Русский',
});

export const AppFlags = Object.freeze({
  en: enflag,
  bg: bgflag,
  de: deflag,
  ru: ruflag,
  el: elflag,
});
