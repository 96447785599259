export const Type = Object.freeze({
  SKI_HIRE: 21,
  BOOTS_HIRE: 22,
  SKI_HIRE_BOOTS_HIRE: 22.5,
  LIFT_PASS: 3,
  LIFT_PASS_EQUIPMENT_STORAGE: 3.5,
  SCHOOL: 4,
  LIFT_PASS_SKI_HIRE: 5,
  LIFT_PASS_SKI_HIRE_BOOTS_HIRE: 6,
  LIFT_PASS_SCHOOL: 7,
  FULL_PACKAGE: 8,
  SMALL_PACKAGE: 8.5,
  LEARN_FULL_PACKAGE: 9,
  FULL_SCHOOL_GROUPS: 10,
  EQUIPMENT_STORAGE: 11,
  RENTED_EQUIPMENT_STORAGE: 11.5,
  CROSS_COUNTRY_TUITUON: 12,
  CROSS_COUNTRY_FULL_PACKAGE: 13,
  SKI_KINDERGARTEN_HALF_DAY: 14,
  SKI_KINDERGARTEN_FULL_DAY: 15,
  NURSERY_NON_SKI: 20,
  HELMET: 16,
  SCHOOL_SHORT: 25,
  LIFT_PASS_SCHOOL_SHORT: 26,
  LIFT_PASS_SKI_HIRE_SCHOOL_SHORT: 27,
  LIFT_PASS_SKI_HIRE_BOOTS_HIRE_SCHOOL_SHORT: 28,
});
