export const Currency = Object.freeze({
  EUR: 1,
  USD: 2,
  GBP: 3,
  BGN: 4,
  RUB: 5,
});
export const CurrencySign = Object.freeze({
  [Currency.EUR]: '&euro;',
  [Currency.USD]: '&usd;',
  [Currency.GBP]: '&pound;',
  [Currency.BGN]: 'lv',
  [Currency.RUB]: 'RUB',
});
