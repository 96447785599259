export type PersonalDataType = {
  name: string;
  phoneNumber: string;
  emailAddress: string;
  myCountryCode?: string;
};

export const defaultPersonalData: PersonalDataType = {
  name: '',
  phoneNumber: '',
  emailAddress: '',
};
